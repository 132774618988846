"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $(".sidebarlink");
    var closeBtn = $(".closelink");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") != true) {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });

      closeBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        }
      });
    }
  },

  changeContent: function changeContent() {},

  init: function init() {
    app.mobileMenu();
  }
};

$('.view-grid').on('click', function () {
  window.location = "./graph.html";
});

$("#details").on("click", ".sidelink", function () {
  var id = $(this).attr("contentId");
  $('#details').fadeOut('slow', function () {
    $(this).html($("#" + id).html()).fadeIn('slow');
  });
});

$('.sidelink').click(function () {
  var id = $(this).attr("contentId");
  //*  var href = ("#topside");
  $('#details').fadeOut('slow', function () {
    //* alert (href)
    //* window.location=href;
    $(this).html($("#" + id).html()).fadeIn('slow');
  });
});

$("#details").on("click", ".closelink", function () {
  var mobileBtn = $(".sidebarlink");
  var closeBtn = $(".closelink");
  var pageBody = $("#page-wrap-inner");

  if (mobileBtn.hasClass("menu-open") == true) {
    pageBody.removeClass("push");
    mobileBtn.removeClass("menu-open");
  }
});

$('#quadrantoptions').hide();
$('#selectbox').hide();

window.quadrantsVisible = function (visible) {
  if (visible) {
    $('input#quadrants').prop('checked', true);
    $('#quadrantoptions').fadeIn();
    $('#selectbox').fadeIn();
    $('.square').addClass("showcolor");
    $('.hoverarea').addClass("active");
    $('.hoverarea').addClass("active");
    $('.tl').addClass("tllink");
    $('.tr').addClass("trlink");
    $('.bl').addClass("bllink");
    $('.br').addClass("brlink");
    $('.tllink').on('click', function () {
      window.location = "./TopLeft.html";
    });
    $('.trlink').on('click', function () {
      window.location = "./TopRight.html";
    });
    $('.bllink').on('click', function () {
      window.location = "./BottomLeft.html";
    });
    $('.brlink').on('click', function () {
      window.location = "./BottomRight.html";
    });
    $('#selectarea').change(function () {
      var area = $('.' + $(this).val());
      var value = $(this).val();
      var square = $('.square');
      var hover = $('.hoverarea');

      square.removeClass("showcolor");
      square.addClass("jibblets");
      area.toggleClass("showcolor");
      area.toggleClass("jibblets");

      if (value == "square") hover.removeClass("norollover");else hover.addClass("norollover");
    });

    $('#selectarea').change(function () {
      var area = $('.' + $(this).val());
      var square = $('.square');

      square.removeClass("showcolor");
      square.addClass("jibblets");
      area.toggleClass("showcolor");
      area.toggleClass("jibblets");
    });

    $('.tl').hover(function () {
      $(".hoverarea").addClass('hover');
      $(".tl").removeClass('hover');
    }, function () {
      $(".hoverarea").removeClass('hover');
    });

    $('.tr').hover(function () {
      $(".hoverarea").addClass('hover');
      $(".tr").removeClass('hover');
    }, function () {
      $(".hoverarea").removeClass('hover');
    });

    $('.bl').hover(function () {
      $(".hoverarea").addClass('hover');
      $(".bl").removeClass('hover');
    }, function () {
      $(".hoverarea").removeClass('hover');
    });

    $('.br').hover(function () {
      $(".hoverarea").addClass('hover');
      $(".br").removeClass('hover');
    }, function () {
      $(".hoverarea").removeClass('hover');
    });
    window.localStorage.setItem('quadrants', true);
  } else {
    $('input#quadrants').prop('checked', false);
    $('#quadrantoptions').fadeOut();
    $('#selectbox').fadeOut();
    $('.square').removeClass("showcolor");
    $('.hoverarea').removeClass("active");
    $(".square").removeClass('hover');
    $('#selectarea').prop('selectedIndex', 0);
    $('.tl').off('click');
    $('.tl').removeClass("tllink");
    $('.tr').off('click');
    $('.tr').removeClass("trlink");
    $('.bl').off('click');
    $('.bl').removeClass("bllink");
    $('.br').off('click');
    $('.br').removeClass("brlink");
    $('.BottomLeft').removeClass("jibblets");
    $('.TopLeft').removeClass("jibblets");
    $('.TopRight').removeClass("jibblets");
    $('.BottomRight').removeClass("jibblets");
    window.localStorage.setItem('quadrants', false);
  }
};

$('#quadrants').change(function () {
  window.quadrantsVisible($(this).is(":checked"));
});

// =====================================================================================


// =====================================================================================

$('.option').hover(function () {
  var currentId = $(this).attr('id');
  $(".square").addClass('hover');
  $('.' + currentId).removeClass('hover');
}, function () {
  $(".square").removeClass('hover');
});

$(document).ready(function () {
  app.init();
  window.quadrantsVisible(JSON.parse(window.localStorage.getItem('quadrants')));
});